import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { openDB } from "idb";
import { BackgroundSyncConstants } from "../../lib/background-sync";

export const schoolApi = createApi({
    reducerPath: "schoolApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_STRAPI_BACKEND, prepareHeaders: async (headers, { endpoint }) =>
        {
            const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

            const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

            const accessToken = data[ 0 ]?.access_token;

            if (accessToken && !endpoint.includes('login') && !endpoint.includes("reset-password")) {
                headers.set('authorization', `Bearer ${accessToken}`);
            }
            db.close();
        }
    }),
    endpoints: (builder) => ({
        getSchools: builder.query<any, string>({
            query: (payload) => "/schools?populate[ward][fields][0]=name&populate[ward][fields][1]=id&populate[local_government][fields][0]=name&populate[local_government][fields][1]=id&populate[state][fields][0]=name&populate[state][fields][1]=id" + payload,
        }),
        getSchoolsByWard: builder.query<any, { ward_id: string, query?: string; sector_type: string; }>({
            query: (payload) => `/schools?filters[sector_type][$eq]=${payload.sector_type}&filters[ward][id][$eq]=${payload.ward_id}${payload.query}`
        }),
        getSchool: builder.query<any, string>({
            query: (id) => `/schools/${id}?populate[ward][fields][0]=name&populate[local_government][fields][0]=name&populate[state][fields][0]=name`,
        }),
        createSchool: builder.mutation<any, any>({
            query: (body) => ({
                url: "/schools",
                method: "POST",
                body: { data: body }
            }),
        }),
        updateSchool: builder.mutation<any, any>({
            query: (body) => ({
                url: `/schools/${body.id}`,
                method: "PUT",
                body: { data: { ...body.data } }
            }),
        }),
        deleteSchool: builder.mutation<any, string>({
            query: (id) => ({
                url: `/schools/${id}`,
                method: "DELETE",
            }),
        }),


    })

});
export const {
    useGetSchoolsQuery,
    useGetSchoolQuery,
    useCreateSchoolMutation,
    useUpdateSchoolMutation,
    useDeleteSchoolMutation,
    useGetSchoolsByWardQuery,


} = schoolApi;
