import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { openDB } from "idb";
import { BackgroundSyncConstants } from "../../lib/background-sync";


export const userApi = createApi({
    reducerPath: "userDataApi",
    tagTypes: [ "User" ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_STRAPI_BACKEND, prepareHeaders: async (headers, { endpoint }) =>
        {
            const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

            const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

            const accessToken = data[ 0 ]?.access_token;

            if (accessToken && !endpoint.includes('login') && !endpoint.includes("reset-password")) {
                headers.set('authorization', `Bearer ${accessToken}`);
            }
            db.close();
        }
    }),
    endpoints: (builder) => ({
        me: builder.mutation({
            query: (id) => ({
                url: `/users/${id}?populate[role]=*&populate[ward][fields][0]=name&populate[local_government][fields][0]=name&populate[state][fields][0]=name`,
                method: 'GET',
            }),
        }),
        firtTmePasswordReset: builder.mutation({
            query: (data) => ({
                url: '/user-management/new-user-password',
                method: 'POST',
                body: data,
            }),
        }),

    })
});

export const { useMeMutation, useFirtTmePasswordResetMutation } = userApi;



