import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { openDB } from 'idb';
import { BackgroundSyncConstants } from '../../lib/background-sync';

export const invitationApi = createApi({
	reducerPath: 'invitationApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_STRAPI_BACKEND,
		prepareHeaders: async (headers, { endpoint }) =>
		{
			const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

			const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

			const accessToken = data[ 0 ]?.access_token;

			if (accessToken && !endpoint.includes('login') && !endpoint.includes('reset-password')) {
				headers.set('authorization', `Bearer ${accessToken}`);
			}
			db.close();
		},
	}),
	endpoints: (builder) => ({
		getUsers: builder.query<any, void>({
			query: (payload) => `/users?${payload}&populate[0]=role`,
		}),
		getRoles: builder.query<any, void>({
			query: () => '/users-permissions/roles',
		}),
		inviteNewUser: builder.mutation<any, any>({
			query: (payload) => ({
				url: '/users',
				method: 'POST',
				body: payload,
			}),
		}),
		updateUser: builder.mutation<any, any>({
			query: (payload) => ({
				url: `/users/${payload.id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		suspendUser: builder.mutation<any, any>({
			query: (payload) => ({
				url: `/users/${payload}`,
				method: 'PUT',
				body: { blocked: true },
			}),
		}),
		activateUser: builder.mutation<any, any>({
			query: (payload) => ({
				url: `/users/${payload}`,
				method: 'PUT',
				body: { blocked: false },
			}),
		}),
		resendInvitation: builder.mutation<any, any>({
			query: (payload) => ({
				url: `/resend-invite`,
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetRolesQuery,
	useInviteNewUserMutation,
	useUpdateUserMutation,
	useResendInvitationMutation,
	useSuspendUserMutation,
	useActivateUserMutation,
} = invitationApi;
