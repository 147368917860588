export const triggerBackgroundSync = () => {
	if ('serviceWorker' in navigator && 'SyncManager' in window) {
		navigator.serviceWorker.ready.then((registration: any) => {
			registration.sync
				.register('sync-state-data')
				.then(() => {
					console.log('Background sync registered')
				})
				.catch((error: any) => {
					console.error('Background sync registration failed:', error)
				})
		})
	}
}
