import React, { useState } from 'react'
import AuthContainer from './AuthContainer'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Navigate, useNavigate } from 'react-router'
import { Button, useToast } from '@chakra-ui/react'

import PasswordField from './FormElements/PasswordField'
import SubmitButton from './FormElements/SubmitButton'
import { useAppSelector, useAppDispatch } from '../../hooks/Redux'
import { useFirtTmePasswordResetMutation } from '../../store/services/user'
import { setUser, setUserRole } from '../../store/features/user/userSlice'

const ChangeDefaultPassword = () => {
	const [reset] = useFirtTmePasswordResetMutation()
	const navigate = useNavigate()
	const toast = useToast()
	const role = useAppSelector((state) => state.user.role?.data?.data?.name)
	const authenticated = useAppSelector((state) => state.user.isAuthenticated)
	const user = useAppSelector((state) => state.user.user)

	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useAppDispatch()

	const formik = useFormik({
		initialValues: {
			password: '',
			confirm_password: '',
		},
		validationSchema: yup.object({
			password: yup
				.string()
				.min(8, 'Password must be a minimum of 8 characters')
				.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
				.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
				.matches(/\d/, 'Password must contain at least one number')
				.matches(/[@$!%*?&]/, 'Password must contain at least one special character')
				.required('Password is required'),
			confirm_password: yup
				.string()
				.oneOf([yup.ref('password')], 'Passwords must match')
				.required('Confirm password field is required'),
		}),
		onSubmit: async (values) => {
			setIsLoading(true)
			try {
				const res: any = await reset({ password: values.password })
				setIsLoading(false)

				if (res?.data) {
					dispatch(setUser({ ...user, is_first_login: false, confirmed: true }))
					dispatch(setUserRole(user?.role))

					toast({
						title: 'Success',
						status: 'success',
						description: res?.data?.message,
						duration: 9000,
						isClosable: true,
					})

					if (user?.role?.name?.toLowerCase() === 'super admin') {
						navigate('/federal/overview')
					} else {
						navigate('/federal/data-entry')
					}
				} else {
					toast({
						title: 'Error',
						status: 'error',
						description: res?.error?.data?.message || 'Something went wrong',
						duration: 9000,
						isClosable: true,
					})

					setIsLoading(false)
				}
			} catch (error) {
				setIsLoading(false)
				toast({
					title: 'Error',
					status: 'error',
					description: 'Something went wrong',
					duration: 9000,
					isClosable: true,
				})
			}
		},
	})

	if (!user?.is_first_login) {
		if (user?.role?.name.toLowerCase() === 'super admin') {
			return <Navigate to={'/federal/overview'} />
		} else {
			return <Navigate to={'/federal/data-entry'} />
		}
	}
	if (!authenticated) {
		return <Navigate to={'/'} />
	}

	return (
		<AuthContainer>
			<div className="relative  w-full  overflow-hidden flex flex-col items-center justify-center text-left text-13xl text-black font-inter">
				<div className="flex-1 rounded-3xl bg-white flex flex-row p-16 items-center justify-center border-[1px] border-solid border-gray-200">
					<div className="self-stretch rounded-xl bg-white w-[100%] max-w-[400px] flex flex-col items-start justify-start gap-[30px]">
						<div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
							<div className="relative font-semibold">Hello!</div>
							<h1 className="relative text-base text-gray-600">Please change your default password</h1>
						</div>
						<form
							className="self-stretch flex flex-col items-center justify-start gap-[48px] sm:self-stretch sm:w-auto [&.animate]:sm:animate-[2s_ease_0s_1_normal_forwards_bounce-in-top] "
							method="post"
							onSubmit={formik.handleSubmit}
						>
							<div className="self-stretch flex flex-col items-end justify-start gap-[12px]">
								<div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
									<PasswordField
										label="New Password"
										name="password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={formik.touched.password && Boolean(formik.errors.password)}
										errorMessage={formik.errors.password}
										value={formik.values.password}
									/>
									<PasswordField
										label="Confirm Password"
										name="confirm_password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
										errorMessage={formik.errors.confirm_password}
										value={formik.values.confirm_password}
									/>
									<div className="flex w-full gap-8 mt-8 justify-between items-center">
										{/* <Button isLoading={isCancelLoading} onClick={handleCancelPassword} className="">
											Cancel
										</Button> */}
										<SubmitButton isLoading={isLoading} text="Reset Password" />
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</AuthContainer>
	)
}

export default ChangeDefaultPassword
