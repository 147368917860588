import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { HiEye, HiEyeSlash } from 'react-icons/hi2'

interface Props {
	isError?: boolean | undefined
	errorMessage?: string
	value: string
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
	onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
	label?: string
	placeholder?: string
	name: string
}
const PasswordField: FC<Props> = ({
	isError,
	errorMessage,
	onChange,
	onBlur,
	value,
	label,
	name,
}) => {
	const [showPassword, setShowPassword] = useState(false)
	return (
		<FormControl isInvalid={isError}>
			<FormLabel className="relative  text-sm font-medium font-inter text-gray-600 text-left">
				{label}
			</FormLabel>
			<InputGroup>
				<InputRightElement
					className="cursor-pointer text-gray-600 "
					onClick={() => setShowPassword((prev) => !prev)}
				>
					{showPassword ? <HiEyeSlash /> : <HiEye />}
				</InputRightElement>
				<Input
					type={showPassword ? 'text' : 'password'}
					value={value}
					name={name}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</InputGroup>
			{isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
		</FormControl>
	)
}

export default PasswordField
