import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	FormHelperText,
	Stack,
} from '@chakra-ui/react'
import React, { FC } from 'react'

interface Props {
	isError?: boolean | undefined
	errorMessage?: string
	type?: string | 'text'
	value?: string
	onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
	onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
	label?: string
	placeholder?: string
	name?: string
	helperText?: string
	inputClassName?: string
	formLabelClassName?: string
}
const InputField: FC<Props> = ({
	isError,
	errorMessage,
	value,
	type,
	onChange,
	onBlur,
	name,
	inputClassName,
	helperText,
	placeholder,
	label,
	formLabelClassName,
}) => {
	return (
		<FormControl isInvalid={isError}>
			<FormLabel
				className={`relative m-0 text-sm font-medium font-inter text-gray-600 text-left ${formLabelClassName}`}
			>
				{label}
			</FormLabel>
			<Stack>
				<Input
					type={type}
					value={value}
					name={name}
					onChange={onChange}
					onBlur={onBlur}
					className={inputClassName + ' form-control'}
					placeholder={placeholder}
				/>
			</Stack>
			{isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	)
}

export default InputField
