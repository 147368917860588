import { Button } from '@chakra-ui/react'

interface Props {
	text: string
	isLoading?: boolean
	disabled?: boolean
	onClick?: () => void
}
const SubmitButton = ({ text, isLoading, disabled, onClick }: Props) => {
	return (
		<Button
			isLoading={isLoading}
			disabled={disabled}
			onClick={onClick}
			type="submit"
			variant="solid"
			colorScheme="green"
			className={`self-stretch font-medium rounded-full bg-success-500 flex flex-row py-2.5 px-3 items-center justify-center text-base text-white ${
				disabled && 'opacity-50'
			}`}
		>
			{text}
		</Button>
	)
}

export default SubmitButton
