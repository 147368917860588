import { createSlice } from '@reduxjs/toolkit'

interface initialStateProp {
	schools: any[]
}

const initialState: initialStateProp = {
	schools: [],
}

const schoolSlice = createSlice({
	name: 'school',
	initialState,
	reducers: {
		editSchoolState: (state, { payload }) => {
			const filterSchool = state.schools.map((school) => {
				if (school.id === payload.id) {
					school = payload
				}
				return school
			})

			state.schools = filterSchool
		},
		getSchools: (state, { payload }) => {
			state.schools = payload
		},
		deleteSchoolState: (state, { payload }) => {
			const filter = state.schools.filter((school) => school.id !== payload)
			state.schools = filter
		},
		addSchoolState: (state, { payload }) => {
			state.schools = [...state.schools, payload]
		},
	},
})

export const { getSchools, deleteSchoolState, editSchoolState, addSchoolState } = schoolSlice.actions

export default schoolSlice.reducer
