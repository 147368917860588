import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { openDB } from "idb";
import { BackgroundSyncConstants } from "../../lib/background-sync";

export const teachersApi = createApi({
    reducerPath: "teachersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_STRAPI_BACKEND, prepareHeaders: async (headers, { endpoint }) =>
        {
            const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

            const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

            const accessToken = data[ 0 ]?.access_token;

            if (accessToken && !endpoint.includes('login') && !endpoint.includes("reset-password")) {
                headers.set('authorization', `Bearer ${accessToken}`);
            }
            db.close();
        }
    }),
    endpoints: (builder) => ({

        addTeacher: builder.mutation({
            query: (body) => ({
                url: "/teachers",
                method: "POST",
                body: { data: body }
            }),
        }),
        deleteTeacher: builder.mutation<any, string>({
            query: (id) => ({
                url: `/teachers/${id}`,
                method: "DELETE",
            }),
        }),
        updateTeacher: builder.mutation<any, any>({
            query: (body) => ({
                url: `/teachers/${body.id}`,
                method: "PUT",
                body: { data: { ...body.data } }
            }),
        }),

        getTeachers: builder.query<any, string>({
            query: (payload) => "/teachers?populate[school][fields][0]=name" + payload,
        }),
        getTeacher: builder.query<any, string>({
            query: (id) => `/teachers/${id}?populate=deep`
        }),
        getTeachersAttendanceBySchool: builder.query<any, { id: string; page?: number; limit?: number; sortColumn?: string; sortDirection?: string; filterDate?: string; }>({
            query: ({ id, page = 1, limit = 10, sortColumn = 'date', sortDirection = 'desc', filterDate = '' }) =>
            {
                let query = `/teacher-attendances?filters[school][id][$eq]=${id}&populate=deep&pagination[page]=${page}&pagination[pageSize]=${limit}&sort=${sortColumn}:${sortDirection}`;

                if (filterDate) {
                    query += `&filters[date][$eq]=${filterDate}`;
                }

                return query;
            }
        }),
        addTeacherAttendance: builder.mutation({
            query: (body) => ({
                url: "/teacher-attendances",
                method: "POST",
                body: { data: body }
            }),
        }),


    })
});


export const {
    useAddTeacherMutation,
    useDeleteTeacherMutation,
    useUpdateTeacherMutation,
    useGetTeachersQuery,
    useGetTeacherQuery,
    useGetTeachersAttendanceBySchoolQuery,
    useAddTeacherAttendanceMutation
} = teachersApi;
