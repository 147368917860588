import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { openDB } from "idb";
import { BackgroundSyncConstants } from "../../lib/background-sync";

export const locationApi = createApi({
    reducerPath: "locationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_STRAPI_BACKEND, prepareHeaders: async (headers, { endpoint }) =>
        {
            const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

            const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

            const accessToken = data[ 0 ]?.access_token;

            if (accessToken && !endpoint.includes('login') && !endpoint.includes("reset-password")) {
                headers.set('authorization', `Bearer ${accessToken}`);
            }
            db.close();
        }
    }),
    endpoints: (builder) => ({
        getStates: builder.query<any, void>({
            query: () => "/states",
        }),
        getSingleState: builder.query<any, string>({
            query: (id) => `/states/${id}`,
        }),
        getLocalGovernments: builder.query<any, any>({
            query: () => `/local-governments`,
        }),
        getSingleLocalGovernment: builder.query<any, string>({
            query: (id) => `/local-governments/${id}`,
        }),
        getWards: builder.query<any, string>({
            query: (id) => `/wards`,
        }),
        getSingleWard: builder.query<any, string>({
            query: (id) => `/wards/${id}`,
        }),
        getLocalGovernmentByStates: builder.query<any, string>({
            query: (id) => `/local-governments?filters[state]=${id}`,
        }),
        getWardByLocalGovernment: builder.query<any, string>({
            query: (id) => `/wards?filters[local_government]=${id}`,
        }),
    })
});


export const {
    useGetStatesQuery,
    useGetSingleStateQuery,
    useGetLocalGovernmentsQuery,
    useGetSingleLocalGovernmentQuery,
    useGetWardsQuery, useGetSingleWardQuery,
    useGetLocalGovernmentByStatesQuery,
    useGetWardByLocalGovernmentQuery
} = locationApi;

