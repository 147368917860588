import { passwordRequest } from '@directus/sdk';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openDB } from 'idb';
import { BackgroundSyncConstants } from '../../../lib/background-sync';
import { authClient } from '../../../lib/directus.config';

interface InitialStateProps
{
	isAuthenticated: boolean;
	user: any;
	role: any;
}

const initialState: InitialStateProps = {
	isAuthenticated: false,
	user: {},
	role: {},
};

export const logoutUser = createAsyncThunk('auth/logout', async () =>
{
	try {
		const res = await authClient.logout();
		const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);
		await db.clear(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);
		db.close();

		return res;
	} catch (error) { }
});

export const resetPasswordRequest = createAsyncThunk(
	'auth/reset-password',
	async ({ email }: { email: string; }, { rejectWithValue }) =>
	{
		try {
			const res = await authClient.request(passwordRequest(email, 'http://localhost:3000/auth/reset-password'));
			return res;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setLogin: (state, { payload }) =>
		{
			state.isAuthenticated = true;
			state.user = payload.user;
		},
		setTokens: (state, { payload }) =>
		{
			state.isAuthenticated = true;
		},
		setUser: (state, { payload }) =>
		{
	;
			state.user = payload;

		},
		setUserRole: (state, { payload }) =>
		{
			state.role = payload;
		},
		handleLogout: (state) =>
		{
			state.isAuthenticated = false;
			state.user = {};
			state.role = {};
		},
	},
	extraReducers: (builder) =>
	{
		builder
			.addCase(resetPasswordRequest.fulfilled, (state, action) =>
			{
				state.isAuthenticated = true;
			})

			.addCase(logoutUser.fulfilled, (state, action) =>
			{
				state.isAuthenticated = false;
				state.user = {};
				state.role = {};
			});
	},
});

export const { setLogin, setTokens, setUser, handleLogout, setUserRole } = userSlice.actions;

export default userSlice.reducer;
